jQuery(function(){

	if(typeof lang_data_locale == 'undefined'){
		lang_data_locale = [];
		lang_data_locale.fill_password = '>Please fill the password field.';
		lang_data_locale.confirm_password = '>Please confirm your password. Make sure the password are identic';
	}

	// ---
	// let plugin validate if js is enable
	jQuery( '#login_customer' ).attr('novalidate', 'novalidate');

	// ---
	// validation plugin
	$.validate();

	// $('form[name=login_customer]').on('submit',function(){
	// 	val_return = true;
	// 	message = '';

	// 	// REINIT form style
	// 	$(this).find('input:visible').each(function(){
	// 		$(this).css('border-color','#a7adb2');
	// 	});
	// 	$('.message--jquery').html('')
		
	// 	$(this).find('input:visible').each(function(){
			
	// 		if(typeof $(this).val() == 'undefined' || $(this).val() == ''){
	// 			$(this).css('border-color','red');
	// 			message += '<p>Fields in red are required</p>';
	// 			val_return = false;
	// 		}	
	// 	})

	// 	if($('input[name=password]').val().length < 5 ){
	// 			$(this).css('border-color','red');
	// 			message += '<p>Password minimum length si 5</p>';
	// 			val_return = false;
	// 	}

	// 	if(!$('input[name=email]').val().match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
	// 			$(this).css('border-color','red');
	// 			message += '<p>Please enter a proper address email </p>';
	// 			val_return = false;
	// 	}




	// 	$('.message--jquery').html(message);

	// 	return val_return;
	// })



	// $('form[name=request-password]').on('submit',function(){
		
	// 	val_return = true;
	// 	message = '';

	// 	// REINIT form style
	// 	$(this).find('input:visible').each(function(){
	// 		$(this).css('border-color','#a7adb2');
	// 	});

	// 	$('.message--jquery').html('')

	// if( $('input[name=reset_type]').val() != 'email' && $('input[name=reset_type]').val() != 'sms'){
	// 			message += '<p>Please choose an appropriate contact method</p>';
	// 			val_return = false;
	// 	}

	// 	if(typeof $('input[name=email]').val() == 'undefined' || $('input[name=email]').val() == ''){
	// 			$('input[name=email]').css('border-color','red');
	// 			message += '<p>Please fill the email field</p>';
	// 			val_return = false;
	// 	}


		
	// 	if(!$('input[name=email]').val().match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
	// 			$(this).css('border-color','red');
	// 			message += '<p>Please enter a proper address email </p>';
	// 			val_return = false;
	// 	}

	// 	$('.message--jquery').html(message);
	// 	$('.message--jquery').css('color','black');

	// 	return val_return;
	// });


	$('form[name=edit-password]').on('submit',function() {

		val_return = true;
		message = '';

		// REINIT form style
		$(this).find('input:visible').each(function(){
			$(this).css('border-color','#a7adb2');
		});


		$('.message--jquery').html('');


		if(typeof $('input[name=password]').val() == 'undefined' || $('input[name=password]').val() == ''){
				$('input[name=password]').css('border-color','red');
				message += '<p>'+lang_data_locale.fill_password+' </p>';
				val_return = false;
			}

		if(typeof $('input[name=password_confirmation]').val() == 'undefined' || $('input[name=password_confirmation]').val() == '' || $('input[name=password_confirmation]').val() != $('input[name=password]').val() ){
			$('input[name=password_confirmation]').css('border-color','red');
			message += '<p>'+lang_data_locale.confirm_password+'</p>'; 
			val_return = false;
		}

		$('.message--jquery').html(message);
		$('.message--jquery').css('color','black');

		return val_return;

	});


});